<template>
  <div>
    <el-row class="login-container">
      <el-col :span="16" style="height: 100%">
        <el-image
          :src="imageSrc"
          fit="cover"
          style="height: 100%; width: 100%;"
        />
      </el-col>
      <el-col :span="8" style="height: 100%">
        <div class="parentDiv">
          <div class="loginFormDiv">
            <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on" label-position="left">
              <div ref="loginRow" class="title-container" style="padding-bottom: 50px">
                <img class="logo" :src="logoImage" alt="">
              </div>
              <el-input
                ref="username"
                v-model="loginForm.username"
                name="username"
                type="text"
                tabindex="1"
                autocomplete="on"
                :placeholder="$t('login.login_user')"
              />
              <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
                <el-input
                  :key="passwordType"
                  ref="password"
                  v-model="loginForm.password"
                  :show-password="true"
                  :type="passwordType"
                  name="password"
                  tabindex="2"
                  autocomplete="on"
                  :placeholder="$t('login.login_password')"
                  @keyup.native="checkCapslock"
                  @blur="capsTooltip = false"
                  @keyup.enter.native="handleLogin"
                />
              </el-tooltip>
              <el-row>
                <el-col>
                  <el-button
                    style="width: 100%"
                    :loading="loading"
                    type="primary"
                    @click.native.prevent="handleLogin"
                  >{{ $t('login.login_button') }}</el-button>
                </el-col>
                <el-col>
                  <el-link
                    plain
                    type="primary"
                    :href="forgotPassUrl"
                  >{{ $t('login.forgotPassword') }}</el-link>
                </el-col>
              </el-row>
              <div :style="`margin-top: 15px`">
                <google-button />
                <apple-button :width="buttonWidth" />
              </div>
              <el-row>
                <el-link
                  plain
                  type="primary"
                  :href="registerUrl"
                >{{ $t('login.register') }}
                </el-link>
              </el-row>
              <div v-if="showAppLinks" style="display: flex;align-items: center;">
                <a href="https://play.google.com/store/apps/details?id=com.fleetmap.manager&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" style="height: 70px" /></a>

                <a href="https://apps.apple.com/us/app/fleetmap-manager/id1549606796?itsct=apps_box_badge&amp;itscg=30200">
                  <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1611532800&h=19edeb74ceeed60844db8e233a482b94" alt="Download on the App Store" style=" width: 150px">
                </a>
              </div>
              <div style="padding-top: 15px">
                <el-tag size="mini" effect="plain" style="float:right">v{{ version }}</el-tag>
              </div>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import Vue from 'vue'
import { getLogo, hasSVG } from '@/utils/partner'
import { cdnUrl } from '@/utils/consts'
import GoogleButton from './GoogleButton'
import { forgotPassword, signUp } from '@/api'
import AppleButton from '@/views/login/AppleButton'
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  components: { GoogleButton, AppleButton },
  data() {
    const validateUsername = (rule, value, callback) => {
      callback()
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error(this.$t('login.login_password_warn')))
      } else {
        callback()
      }
    }
    return {
      connected: false,
      logMessage: '',
      shareScreen: false,
      buttonWidth: 0,
      appleImageSrc: '',
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      passwordType: 'password',
      capsTooltip: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {}
    }
  },
  computed: {
    ...mapGetters(['loading']),
    showAppLinks() {
      return window.location.hostname === 'vz.samtech.cl'
    },
    registerUrl() {
      return signUp()
    },
    forgotPassUrl() {
      return forgotPassword()
    },
    hasSVG() {
      return hasSVG()
    },
    logoImage() {
      return getLogo()
    },
    imageSrc() {
      return `${cdnUrl}/images/login_${(new Date().getTime() % 7) + 1}.jpg`
    },
    version() {
      let v = process.env.PACKAGE_VERSION
      if (process.env.NODE_ENV === 'development') {
        v += '-dev'
      }
      return v
    }
  },
  mounted() {
    if (this.$refs.username && this.loginForm.username === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password && this.loginForm.password === '') {
      this.$refs.password.focus()
    }
  },
  methods: {
    checkCapslock({ shiftKey, key } = {}) {
      if (key && key.length === 1) {
        this.capsTooltip = shiftKey && (key >= 'a' && key <= 'z') || !shiftKey && (key >= 'A' && key <= 'Z')
      }
      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false
      }
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$store.commit('transient/SET_LOADING', true)
          Vue.$log.debug('dispatch user login ', this.loginForm)
          this.$store.dispatch('user/login', this.loginForm)
            .catch(exception => {
              Vue.$log.warn(exception)
              let message = this.$t('login.login_user_password_invalid')
              if (exception.response && exception.response.status === 400) {
                message = this.$t('login.login_user_disabled')
              } else if (exception.code === 'NetworkError') {
                message = this.$t('login.network_error')
              }
              this.$message({
                message: message,
                type: 'error',
                duration: 10 * 1000
              })
              this.$store.commit('transient/SET_LOADING', false)
            })
        } else {
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }

  }
}

</script>

<style lang="scss">
  @import '../../styles/element-variables.scss';

  .login-container {
    font-size: 18px;
    height: 100vh;

    .parentDiv {
      height: 100%;
      width: 100%;
      position: relative;

      .loginFormDiv {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .login-form {
          padding: 8%;
        }

        .title-container {
          width: 100%;
          text-align: center;
          .logo {
            width:100%;
            min-height: 100px;
          }

        }

        .el-input {
          display: inline-block;
          height: 47px;
          width: 100%;

          input {
            border-right-width: 0;
            border-left-width: 0;
            border-top-width: 0;
            border-bottom-width: 2px;
            border-radius: 0;
            color: $--color-info;
          }
        }

        .el-button-login {
          width: 100px;
        }

        .el-form-item {
          border-radius: 5px;
          color: #454545;
        }

        .el-form-item__label {
          height: 25px;
          color: $--color-info;
          font-size: 14px;
        }

        .show-pwd {
          position: absolute;
          right: 10px;
          top: 7px;
          font-size: 16px;
          color: $--color-info;
          cursor: pointer;
          user-select: none;
        }

        .el-button {
          width: 50%;
          alignment: right;
        }
      }
    }
  }
</style>
