<template>
  <div class="google-btn" @click="click">
    <div>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="google-icon"><g fill-rule="evenodd" clip-rule="evenodd"><path d="M20.64 12.2c0-.63-.06-1.25-.16-1.84H12v3.49h4.84a4.14 4.14 0 0 1-1.8 2.71v2.26h2.92a8.78 8.78 0 0 0 2.68-6.61z" fill="#4285F4"></path><path d="M12 21a8.6 8.6 0 0 0 5.96-2.18l-2.91-2.26a5.41 5.41 0 0 1-8.09-2.85h-3v2.33A9 9 0 0 0 12 21z" fill="#34A853"></path><path d="M6.96 13.71a5.41 5.41 0 0 1 0-3.42V7.96h-3a9 9 0 0 0 0 8.08l3-2.33z" fill="#FBBC05"></path><path d="M12 6.58c1.32 0 2.5.45 3.44 1.35l2.58-2.58A9 9 0 0 0 3.96 7.96l3 2.33A5.36 5.36 0 0 1 12 6.6z" fill="#EA4335"></path></g></svg>
    </div>
    <div class="btn-text">
      <a @click="click">
        <b>
          {{ $t('login.signInWithGoogle') }}
        </b>
      </a>
    </div>
  </div>
</template>
<script>
import { Auth } from '@aws-amplify/auth'

export default {
  name: 'GoogleButton',
  props: {
    withLink: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    async click() {
      console.log(await Auth.federatedSignIn({ provider: 'Google' }))
    }
  }
}
</script>
<style lang="scss" scoped>
  $white: #fff;
  $google-blue: #4285f4;
  $button-active-blue: #1669F2;

  .google-icon {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 11px;
    padding-bottom: 11px;
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
  }
  .btn-text {
    padding-left: 16px;
    padding-right: 8px;
    color: #808080;
    font-size: 14px;
    font-family: "Roboto",serif;
  }
  .google-btn {
    width: 220px;
    cursor:pointer;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.25);
    display:flex;
    flex-direction: row;
    height: 40px;
    border-radius: 2px;
    align-items: center;
    border: 1px solid;
    border-color: rgba(0,0,0,.05);;
  }
  @import url(https://fonts.googleapis.com/css?family=Roboto);
</style>
